.voo-pillars {
  position: relative;
  background-color: $c-blue-darker;
}

.voo-pillars-inner {
  padding-top: $spacing;

  @include mq('l') {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: $spacing * 3;
  }

  @include mq('xl') {
    padding-top: 0;
  }
}

.voo-pillars__title {
  @extend %fw-bold;

  width: col(12);
  margin: 0;
  color: $c-light;
  font-family: $ff-alt;
  font-size: 3rem;
  line-height: 1.25;

  @include mq($until: 'm') {
    padding: $spacing * 1.5 0 $spacing;
  }

  @include mq('m') {
    width: col(3);
  }

  @include mq('l') {
    width: col(4);
  }

  @include mq('xl') {
    width: col(3.5);
  }
}

.voo-pillars__list {
  @extend %list-nostyle;

  display: flex;
  width: 100%;
  max-width: 42rem;

  @include mq($until: 'm') {
    justify-content: space-between;
  }

  @include mq('m') {
    margin-left: -$spacing;
  }

  @include mq('l') {
    max-width: 56rem;
    margin-left: -$spacing * 0.5;
  }

  @include mq('xl') {
    margin-left: $spacing;
  }
}

.voo-pillars__item__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing 0;
  text-decoration: none;
  transition: background-color 0.3s $ease-out-quart;

  @include mq('m') {
    flex-direction: row;
    margin-right: $spacing * 2;
    padding: $spacing * 2 $spacing;

    &:hover {
      background-color: lighten($c-blue-dark, 10%);
    }
  }

  @include mq('l') {
    margin-right: $spacing;
    padding: $spacing * 2 $spacing * 1.5;
  }

  @include mq('xl') {
    margin-right: $spacing * 2;
  }
}

.voo-pillars__item__link__icon {
  height: 3rem;
  fill: $c-light;

  @include mq($until: 'm') {
    width: 5.5rem;
    margin-bottom: 0.8rem;
  }

  @include mq('m') {
    width: auto;
    margin-right: $spacing / 2;

    .ie & {
      width: 5;
    }
  }
}

.voo-pillars__item__link__label {
  @extend %fw-ultra;
  @extend %text-uppercase;

  color: $c-light;
  font-size: 1.1rem;
  letter-spacing: 1.2px;
}

.voo-pillars__item__link__arrow {
  display: none;

  @include mq('m') {
    display: inline-flex;
    width: 0.6rem;
    height: 1.8rem;
    margin-left: 0.8rem;
    fill: $white;
  }
}

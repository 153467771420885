.label,
[class*='label--'] {
  @extend %fw-bold;
  @extend %color-light;

  display: inline-block;
  padding: 0.5rem $spacing / 1.5;
  font-size: 1.1rem;
  line-height: 1;
  text-transform: uppercase;
  background: $c-blue-pale;
  border-radius: 3px;

  &[class*='--rounded'] {
    @extend %fw-black;

    font-size: 0.9rem;
    letter-spacing: 0.6px;
    border-radius: 10px;
  }

  &[class*='--outline'] {
    color: $c-blue-pale;
    background: transparent !important;
    border: 1px solid $c-blue-pale;
  }

  &[class*='--outline'][class*='--pink'] {
    color: $c-pink-medium;
    border: 1px solid $c-pink-medium;
  }

  &[class*='--pink'] {
    background: $c-pink-medium;
  }

  &[class*='--outline'][class*='--cyan'] {
    color: $c-cyan-contrast;
    border: 1px solid $c-cyan-contrast;
  }

  &[class*='--outline'][class*='--white'] {
    color: #fff;
    border: 1px solid #fff;
  }

  &[class*='--cyan'] {
    background: $c-cyan-contrast;
  }

  &[class*='--red'] {
    background: $c-dev-error;
  }

  &[class*='--blue'] {
    background: #217078;
  }

  &[class*='--golden'] {
    background: linear-gradient(53.04deg, #fce6ae 5.01%, #8a5a12 89.13%);
  }

  &[class*='--outline'][class*='--yellow'] {
    color: $c-orange;
    border: 1px solid $c-orange;
  }

  &[class*='--yellow'] {
    background: $c-orange;
  }

  &[class*='--green'] {
    background: $c-green-light;
  }

  &[class*='--white'] {
    color: #d4107a;
    background: #fff;
  }

  &[class*='--unavailable'] {
    background: $gray-light;
  }

  @include mq($until: m) {
    line-height: 1.3;
  }

  @include mq(m) {
    font-size: 1.3rem;
  }
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        











































































































.smartphones-specs [class*='smartphones-specs--'] {
}

.smartphones-specs__title {
  margin: 0 0 $spacing * 3.5;

  @include mq(l) {
    margin: 0 0 $spacing * 4.5;
  }
}

.smartphones-specs__list {
  margin: 0 0 $spacing * 3.5;
  padding: 0;
}

.smartphones-specs__list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $black;
  padding: $spacing * 0.75 0;
  list-style-type: none;

  @include mq(l) {
    padding: $spacing 0;
  }
}

.smartphones-specs__list__item__color {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;

  @include mq(l) {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.smartphones-specs__perks {
  margin: 0;
  border: 1px solid $c-pink-medium;
  padding: $spacing $spacing * 1.5;

  @include mq(l) {
    padding: $spacing * 1.5 $spacing * 1.75;
  }
}

.smartphones-specs__perks__item {
  display: flex;
  align-items: center;
  list-style-type: none;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    margin-right: $spacing * 0.5;
    fill: $c-pink-medium;
  }

  @include mq(l) {
    &:not(:last-child) {
      margin-bottom: $spacing;
    }

    .icon {
      width: 3.2rem;
      height: 3.2rem;
      margin-right: $spacing * 0.75;
    }
  }
}

/* stylelint-disable declaration-no-important */
[class*='h-spacer-s'] {
  padding-top: get-spacing(s, s) !important;
  padding-bottom: get-spacing(s, s) !important;

  @include mq(m) {
    padding-top: get-spacing(s, m) !important;
    padding-bottom: get-spacing(s, m) !important;
  }

  @include mq(xl) {
    padding-top: get-spacing(s, l) !important;
    padding-bottom: get-spacing(s, l) !important;
  }
}

[class*='h-spacer-m'] {
  padding-top: get-spacing(m, s) !important;
  padding-bottom: get-spacing(m, s) !important;

  @include mq(m) {
    padding-top: get-spacing(m, m) !important;
    padding-bottom: get-spacing(m, m) !important;
  }

  @include mq(xl) {
    padding-top: get-spacing(m, l) !important;
    padding-bottom: get-spacing(m, l) !important;
  }
}

[class*='h-spacer-l'] {
  padding-top: get-spacing(l, s) !important;
  padding-bottom: get-spacing(l, s) !important;

  @include mq(m) {
    padding-top: get-spacing(l, m) !important;
    padding-bottom: get-spacing(l, m) !important;
  }

  @include mq(xl) {
    padding-top: get-spacing(l, l) !important;
    padding-bottom: get-spacing(l, l) !important;
  }
}

[class*='h-spacer-xl'] {
  padding-top: get-spacing(xl, s) !important;
  padding-bottom: get-spacing(xl, s) !important;

  @include mq(m) {
    padding-top: get-spacing(xl, m) !important;
    padding-bottom: get-spacing(xl, m) !important;
  }

  @include mq(xl) {
    padding-top: get-spacing(xl, l) !important;
    padding-bottom: get-spacing(xl, l) !important;
  }
}

[class*='h-spacer-xxl'] {
  padding-top: get-spacing(xxl, s) !important;
  padding-bottom: get-spacing(xxl, s) !important;

  @include mq(m) {
    padding-top: get-spacing(xxl, m) !important;
    padding-bottom: get-spacing(xxl, m) !important;
  }

  @include mq(xxxl) {
    padding-top: get-spacing(xxl, l) !important;
    padding-bottom: get-spacing(xxl, l) !important;
  }
}

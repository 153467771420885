.wysiwyg,
[class*='wysiwyg--'] {
  @extend %fw-light;

  h2,
  .h2 {
    font-size: 1.8em;
  }

  h3,
  .h3 {
    font-size: 1.6em;
  }

  h4,
  .h4 {
    font-size: 1.4em;
  }

  p {
    @include mq(m) {
      margin: $spacing * 2 0;
    }
  }

  a:not(.btn),
  a:visited:not(.btn) {
    color: $c-pink-medium;
  }

  ul {
    padding-inline-start: 20px;
    padding-left: 22px;
  }

  li {
    @include mq(m) {
      margin: 1.5rem * 1 0;
      padding-left: 10px;
    }
  }

  li::marker {
    color: $c-pink-medium;
  }

  b {
    color: $c-pink-medium;

    .blog-single__main & {
      color: $c-gray-darkest;
    }
  }

  .lead {
    font-size: 1.8rem;

    @include mq(m) {
      font-size: 2.4rem;
    }
  }

  table {
    @extend %text-center;

    /* stylelint-disable-next-line declaration-no-important */
    width: auto !important;
    border: none;

    /* stylelint-disable-next-line selector-max-type */
    tr:first-child td:first-child {
      border-top-left-radius: 1rem;
    }

    /* stylelint-disable-next-line selector-max-type */
    tr:first-child td:last-child {
      border-top-right-radius: 1rem;
    }

    /* stylelint-disable-next-line selector-max-type */
    tr:last-child td:first-child {
      border-bottom-left-radius: 1rem;
    }

    tr:last-child {
      border-bottom: 1px solid $c-gray-lightest;
    }

    /* stylelint-disable-next-line selector-max-type */
    tr:last-child td:last-child {
      border-bottom-right-radius: 1rem;
    }

    /* stylelint-disable-next-line selector-max-type */
    tr:first-child th:first-child {
      border-top-left-radius: 1rem;
    }

    /* stylelint-disable-next-line selector-max-type */
    tr:first-child th:last-child {
      border-top-right-radius: 1rem;
    }

    /* stylelint-disable-next-line selector-max-type */
    tr:last-child th:first-child {
      border-bottom-left-radius: 1rem;
    }

    /* stylelint-disable-next-line selector-max-type */
    tr:last-child th:last-child {
      border-bottom-right-radius: 1rem;
    }

    @include mq($until: m) {
      font-size: 1.3rem;
    }

    @include mq(l) {
      margin-inline: -12rem;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  tr {
    /* stylelint-disable-next-line no-descending-specificity */
    &:nth-child(odd) {
      background-color: $c-light-gray;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &:first-child {
      background-color: $c-pink-medium;

      /* stylelint-disable-next-line no-descending-specificity */
      td {
        @extend %text-uppercase;

        color: $white;
        font-family: $ff-alt;
        line-height: 1.5;
        border: 0;

        /* stylelint-disable-next-line max-nesting-depth */
        @include mq(m) {
          padding: $spacing / 2;
        }
      }
    }
  }

  .td {
    border: 0;
    border-right: 1px solid $c-gray-lightest;
    border-left: 1px solid $c-gray-lightest;

    @include mq(l) {
      padding: $spacing / 2;
    }
  }

  // table {
  //   width: 100%;
  //   text-align: center;
  //   border-collapse: separate;
  //   border-spacing: $spacing / 2 $spacing / 4;

  //   thead {
  //     @extend %fw-bold;

  //     color: $white;
  //     font-size: 16px;
  //     line-height: 2;
  //     background-color: $c-blue-medium;
  //   }

  //   .cell {
  //     font-size: 12px;
  //     border-bottom: 1px dotted $c-blue-pale;
  //   }

  //   .highlight,
  //   [class*='highlight--'] {
  //     color: $white;
  //     font-size: 12px;
  //     line-height: 1.7;
  //     background-color: $c-blue-pale;

  //     &[class*='--pink'] {
  //       background-color: $c-pink-medium;
  //     }
  //   }

  //   @include mq(l) {
  //     border-spacing: $spacing $spacing / 4;
  //   }
  // }

  .webdeal {
    color: $c-orange;
  }

  .wysiwyg-video {
    display: flex;
    margin-top: 2rem;

    iframe {
      max-width: 100%;
    }
  }
}

/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.voo-submenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 7rem;
  padding: 0 $spacing;
  transition: background-color 0.5s ease-out;

  // Hide on configurator
  [data-template='configurator'] & {
    display: none;
  }

  .sticky & {
    background-color: $white !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

    [data-scheme='transparent'] & {
      background-color: transparent !important;
      box-shadow: none;
    }

    @include mq(l) {
      // [data-template='blog'] &,
      // [data-template='employee-advocacy'] &,
      [data-scheme='dark'] &,
      .voo-app--be.chrome-light & {
        background-color: $c-blue-dark !important;
      }
    }
  }

  .menu-open & {
    opacity: 0 !important;
    pointer-events: none;
    transition: opacity 0.2s 0s ease-out !important;
  }

  @include mq($until: l) {
    margin-top: $spacing * 2.5;
    padding: 0;
  }

  @include mq(l) {
    padding: $spacing;
    transform: translate(0, 170%);
  }

  @include mq(xl) {
    transform: translate(0, 150%);
  }
}

.voo-submenu__list {
  @extend %list-nostyle;

  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  outline: none;

  @include mq(l) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .flickity-viewport {
    width: 100%;
  }

  @include mq($until: l) {
    border-top: 1px solid $c-blue-light-pale;
    border-bottom: 1px solid $c-blue-light-pale;
  }
}

.voo-submenu__list__item,
[class*='voo-submenu__list__item--'] {
  position: relative;
  flex-shrink: 0;

  &:first-child {
    padding-left: 2rem;
  }

  &:last-child {
    padding-right: 2rem;
  }

  & + & {
    margin-left: $spacing * 2;

    &::after {
      @include center-y;

      content: '';
      left: -$spacing;
      display: block;
      width: 1px;
      height: 2.5rem;
      background-color: $c-blue-light-pale;

      [data-scheme='dark'] & {
        background-color: rgba($c-blue-light-pale, 0.4);
      }

      [data-scheme='transparent'] & {
        @include mq(l) {
          background: transparentize($color: $white, $amount: 0.5);
        }
      }
    }
  }

  a {
    @extend %fw-medium;

    position: relative;
    top: -1px;
    color: $c-blue-medium;
    font-size: 1.4rem;
    text-decoration: none;
    white-space: nowrap;
    transition: color 0.1s $ease-out;

    @include mq(l) {
      color: $c-blue-dark;
    }

    &:hover {
      color: $c-pink-medium !important;
    }

    // [data-template='blog'] &,
    [data-scheme='dark'] &,
    [data-scheme='transparent'] &,
    [data-template='employee-advocacy'] &,
    .voo-app--be.chrome-light & {
      @include mq(l) {
        color: $white !important;

        &.router-link-active {
          color: $c-pink-medium !important;
        }

        &:hover {
          color: $c-pink-medium !important;
        }
      }
    }

    [data-scheme='transparent'] & {
      color: $white !important;
      text-shadow: 0 0 5px #0000003b;

      &.router-link-active {
        color: $c-pink-medium !important;
      }

      &:hover {
        color: $c-pink-medium !important;
      }
    }

    &.router-link-active {
      color: $c-pink-medium !important;
    }

    &.router-link-exact-active {
      color: $c-pink-medium !important;
      pointer-events: none;
    }
  }
}

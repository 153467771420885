
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        













.error {
  margin: 2rem;
  padding: 0 2rem 2rem;
  border: 1px dotted $c-dev-error;
}

.error__box,
.error__stack {
  margin: 1rem 0 0;
  padding: 2rem;
  border: 1px dashed $c-dev-error;
}

.error__box {
  color: $white;
  font-size: 1.2em;
  background-color: $c-dev-error;
}

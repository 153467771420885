// .voo-header-btn__link-outer {
//   & + & {
//     border-top: 1px solid $c-blue-medium;
//   }
// }

.voo-header-btn__link {
  @extend %fw-bold;

  display: flex;
  align-items: center;
  padding: $spacing $spacing * 2;
  color: $c-blue-dark;
  font-family: $ff-alt;
  font-size: 1.8em / $body-text-s * 1em;
  line-height: 1.25;
  // background-color: $c-blue-dark;
  text-decoration: none;
  border-top: 1px solid $c-gray-light;

  @include mq(m) {
    padding-left: $spacing * 4;
    font-size: 2.2em / $body-text-m * 1em;
  }

  @include mq(l) {
    font-size: 2.4em / $body-text-l * 1em;
  }

  // Mobile Landscape 🪟
  @media screen and (max-height: 600px) {
    padding-top: $spacing * 0.6;
    padding-bottom: $spacing * 0.6;
    font-size: 1.6rem;
  }
}

.voo-header-btn__link__icon {
  margin-right: $spacing / 2;
  fill: $c-pink-medium;
}

a {
  color: $c-blue-dark;

  &:hover {
    text-decoration: none;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  @extend %fw-bold;

  font-family: $ff-alt;

  strong {
    @extend %fw-black;
  }
}

h1,
.h1 {
  margin-bottom: 0.75em;
  font-size: 3.6em / $body-text-s * 1em;
  line-height: 1.115;

  @include mq(m) {
    margin-bottom: 0.6em;
    font-size: 4.8em / $body-text-m * 1em;
  }

  @include mq(l) {
    margin-bottom: 0.45em;
    font-size: 7.2em / $body-text-l * 1em;
  }
}

h2,
.h2 {
  font-size: 2.8em / $body-text-s * 1em;
  line-height: 1.17;

  strong {
    font-weight: 900 !important; // sass-lint:disable-line no-important
  }

  @include mq(m) {
    font-size: 3.2em / $body-text-m * 1em;
  }

  @include mq(l) {
    font-size: 4.8em / $body-text-l * 1em;
  }
}

h3,
.h3 {
  font-size: 2.2em / $body-text-s * 1em;
  line-height: 1.2;

  @include mq(m) {
    font-size: 2.8em / $body-text-m * 1em;
  }

  @include mq(l) {
    font-size: 3.2em / $body-text-l * 1em;
  }
}

h4,
.h4 {
  font-size: 1.8em / $body-text-s * 1em;
  line-height: 1.25;

  @include mq(m) {
    font-size: 2.2em / $body-text-m * 1em;
  }

  @include mq(l) {
    font-size: 2.4em / $body-text-l * 1em;
  }
}

h5,
.h5 {
  font-size: 1.5em / $body-text-s * 1em;
  line-height: 1.3;

  @include mq(m) {
    font-size: 1.6em / $body-text-m * 1em;
  }

  @include mq(l) {
    font-size: 1.8em / $body-text-l * 1em;
  }
}

.lead {
  @extend %fw-light;

  margin-bottom: $body-text-s * 2;
  font-family: $ff-default;
  font-size: 1.8rem;
  line-height: 1.5;

  strong {
    @extend %fw-medium;
  }

  @include mq(m) {
    margin-bottom: $body-text-m * 2;
    font-size: 2.2rem;
  }

  @include mq(l) {
    margin-bottom: $body-text-l * 2;
    font-size: 2.4rem;
  }
}

.quote {
  @extend %fw-light;

  font-family: $ff-alt;
  font-size: 2.2rem;
  line-height: 1.2;

  strong {
    @extend %fw-bold;
  }

  @include mq(m) {
    font-size: 2.8rem;
  }

  @include mq(xl) {
    font-size: 3.2rem;
  }
}

.label-tiny,
.label-small,
.label-medium,
.label-big {
  font-family: $ff-alt;
  line-height: 1.33;
  text-transform: uppercase;
}

.label-tiny,
.label-small,
.label-medium {
  @extend %fw-medium;
}

.label-big {
  @extend %fw-light;

  font-size: 1.8rem;
  letter-spacing: 1px;

  @include mq(m) {
    font-size: 2.2rem;
    letter-spacing: 1.2px;
  }

  @include mq(xl) {
    font-size: 2.4rem;
    letter-spacing: 1px;
  }
}

.label-small,
.label-medium {
  letter-spacing: 0.8px;

  @include mq(m) {
    letter-spacing: 0.9px;
  }

  @include mq(xl) {
    letter-spacing: 1px;
  }
}

.label-medium {
  font-size: 1.5rem;

  @include mq(m) {
    font-size: 1.6rem;
  }

  @include mq(xl) {
    font-size: 1.8rem;
  }
}

.label-small {
  font-size: 1.3rem;

  @include mq(m) {
    font-size: 1.4rem;
  }

  @include mq(xl) {
    font-size: 1.5rem;
  }
}

.category,
.label-tiny {
  font-size: 0.9rem;
  letter-spacing: 0.6px;

  @include mq(m) {
    font-size: 1rem;
    letter-spacing: 0.7px;
  }

  @include mq(xl) {
    font-size: 1.1rem;
    letter-spacing: 0.8px;
  }
}

.category {
  @extend %fw-black;

  font-family: $ff-alt;
  line-height: 1.33;
}

.text-tiny,
.text-small {
  font-family: $ff-default;
  line-height: 1.33;

  strong {
    @extend %fw-bold;
  }
}

.text-small {
  @extend %fw-light;

  font-size: 1.3rem;

  @include mq(m) {
    font-size: 1.4rem;
    line-height: 1.6;
  }

  @include mq(xl) {
    font-size: 1.5rem;
    line-height: 1.8;
  }
}

.text-tiny {
  @extend %fw-normal;

  font-size: 1.1rem;

  @include mq(m) {
    font-size: 1.2rem;
  }

  @include mq(xl) {
    font-size: 1.3rem;
  }
}

.visual-linebreak {
  &::before {
    content: '';
    display: block;
  }
}

/* stylelint-disable declaration-no-important */
/* stylelint-disable declaration-colon-newline-after */

.link,
[class*='link--'] {
  @extend %fw-bold;

  position: relative;
  display: inline-block !important;
  color: $c-gray-darkest;
  font-family: $ff-alt;
  font-size: 1.5rem;
  line-height: 1.5;
  text-decoration: none;
  transition: color 0.3s $ease-out-quad;

  &,
  span {
    display: inline-block !important;
    background-image: linear-gradient($c-gray-darkest, $c-gray-darkest),
      linear-gradient($c-pink-medium, $c-pink-medium);
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 2px, 0 2px;
    transition: background-size 0.3s $ease-out-quad,
      background-position 0.3s $ease-out-quad;
  }

  &:hover,
  &:focus {
    color: $c-pink-medium !important;

    &,
    span {
      background-position: 100% 100%, 0 100%;
      background-size: 0 2px, 100% 2px;
    }
  }

  &[class*='--inverse'] {
    color: $white !important;

    &,
    span {
      background-image: linear-gradient($white, $white),
        linear-gradient($c-purple, $c-purple);
    }

    &:hover,
    &:focus {
      color: $c-purple !important;
    }
  }

  &[class*='--reverse'] {
    color: $c-pink-medium !important;

    &,
    span {
      background-image: linear-gradient($c-pink-medium, $c-pink-medium),
        linear-gradient($white, $white);
    }

    &::before {
      background-color: $white !important;
    }

    &:hover,
    &:focus {
      color: $white !important;
    }
  }

  &[class*='--blue-pale'] {
    color: $c-anthracite-light;

    &,
    span {
      background-image: linear-gradient(
          $c-anthracite-light,
          $c-anthracite-light
        ),
        linear-gradient($white, $white);
    }
  }

  &[class*='--pink'] {
    color: $c-pink-medium;

    &,
    span {
      background-image: linear-gradient($c-pink-medium, $c-pink-medium),
        linear-gradient($c-blue-dark, $c-blue-dark);
    }

    &:hover,
    &:focus {
      color: $c-blue-dark !important;
    }
  }

  &[class*='--nounderline'] {
    &,
    span {
      background-image: none;
    }

    &::after,
    &::before {
      content: '';
      display: none;
    }
  }

  &[class*='--white'] {
    color: $white !important;

    &,
    span {
      background-image: linear-gradient($white, $white),
        linear-gradient($c-pink-medium, $c-pink-medium);
    }

    &:hover,
    &:focus {
      color: $c-pink-medium !important;
    }
  }

  @include mq(m) {
    font-size: 1.6rem;
  }

  @include mq(xl) {
    font-size: 1.8rem;
  }
}

.card.has-link:hover {
  .link {
    color: $c-pink-medium !important;
    background-size: 100% 2px;
  }

  .link--pink {
    color: $c-blue-dark !important;
    background-size: 100% 2px;

    &,
    span {
      background-position: 100% 100%, 0 100%;
      background-size: 0 2px, 100% 2px;
    }
  }
}

.voo-lang-switcher {
  @extend %text-uppercase;

  position: relative;
  z-index: 99;
}

.voo-lang-switcher-outer {
  @extend %fw-bold;

  height: 5rem;
  padding: 0.3rem $spacing * 2 0 $spacing * 0.75;
  color: $c-light;
  font-family: $ff-alt;
  font-size: 1.1rem;
  letter-spacing: 1.2px;
  cursor: pointer;
}

.voo-lang-switcher-inner {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  clip: rect(0, 100vw, 0, 0);
  transform: translateY(100%);
  cursor: pointer;
  // overflow: hidden;
  // height: 5rem;

  .is-open & {
    // border: 1px solid $c-blue-medium;

    .ie & {
      overflow: visible;
    }
  }

  @include mq($until: 'nav') {
    top: 0;
    bottom: initial;
    transform: translateY(-100%);
    clip: rect(150px, 100vw, 150px, 0);
  }
}

.voo-lang-switcher__item {
  @extend %fw-bold;

  height: 5rem;
  padding: 0 $spacing * 2 0 $spacing * 0.75;
  color: rgba($c-light, 0.5);
  font-family: $ff-alt;
  font-size: 1.1rem;
  text-decoration: none;
  letter-spacing: 1.2px;
  background-color: $c-blue-dark;
  transition: 0.2s $ease-out-quart;
  transition-property: background-color, color;

  a {
    color: rgba($c-light, 0.5);
    text-decoration: none;
  }

  &.is-active {
    display: none;
    color: rgba($c-light, 1);
    pointer-events: none;

    a {
      color: rgba($c-light, 1);
    }
  }

  &:hover {
    color: rgba($c-light, 1);
    background-color: lighten($c-blue-dark, 10%);
  }
}

.voo-lang-switcher__icon {
  position: absolute;
  top: 2.2rem;
  right: 2rem;
  align-items: center;
  width: 1rem;
  height: 1rem;
  fill: $c-light;
  pointer-events: none;
  transform-origin: center center;
  transition: transform 0.2s ease-out;

  @include mq($until: 'nav') {
    transform: rotate(180deg);
  }
}

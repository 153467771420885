
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























.conditions-item,
[class*='conditions-item--'] {
  @include bg-color;
}

.conditions-item__text {
  margin-top: $spacing;
}

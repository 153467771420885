/* stylelint-disable declaration-no-important */

.voo-footer-menu-item__content {
  @include mq($until: 'm') {
    flex: 1;
    order: 2;
    margin-left: 1rem;
  }
}

.voo-footer-menu-item__title {
  @extend %fw-bold;

  font-family: $ff-alt;
  font-size: 1.6rem;
  line-height: 2.4rem;

  @include mq($until: 'm') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: $spacing * 0.75 0;

    span {
      pointer-events: none;
    }
  }

  @include mq('m') {
    margin-bottom: $spacing;
  }
}

.voo-footer-menu-item__title__icon {
  display: none;

  @include mq($until: 'm') {
    display: inline-flex;
    flex-shrink: 0;
    margin-left: $spacing * 0.5;
    fill: $white;
    pointer-events: none;
    transition: transform 0.3s ease-out;

    .is-open & {
      transform: rotate(180deg);
    }
  }
}

.voo-footer-menu-item__list {
  @extend %list-nostyle;

  @include mq($until: 'm') {
    position: relative;
    overflow: hidden;
    height: 0;

    .voo-footer-menu-item__list__item {
      &:first-child {
        padding-top: $spacing * 0.5;
      }

      &:last-child {
        padding-bottom: $spacing * 0.75;
      }
    }
  }

  @include mq('m') {
    height: auto !important;
  }
}

.voo-footer-menu-item__list__item {
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  line-height: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.voo-footer-menu-item__list__item__link {
  color: $white;
  text-decoration: none;
  transition: color 0.2s $ease-out-quart;

  &:hover {
    color: $c-pink-medium;
  }
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

































































































































































.smartphone-item {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 17rem;
  height: 17rem;
  text-decoration: none;
  transition: 0.2s $ease-out-quad;
  transition-property: opacity, transform;

  &.is-viewed {
    opacity: 0;
    transform: scale(0.7);
  }

  @include mq('m') {
    width: 25rem;
    height: 20rem;
  }

  @include mq('xl') {
    width: 35rem;
    height: 26rem;
  }
}

.smartphone-item__picture-outer {
  position: relative;
  flex-shrink: 0;
  width: 8rem;
  height: 16rem;

  @include mq('m') {
    width: 10rem;
    height: 20rem;
  }

  @include mq('xl') {
    width: 13rem;
    height: 25rem;
  }
}

.smartphone-item__picture {
  @include image-fit(contain);

  top: initial;
}

.smartphone-item__misc,
[class*='smartphone-item__misc--'] {
  margin-left: $spacing / 2;
  color: $c-blue-dark;

  .is-reverse & {
    @extend %box-shadow;

    color: $c-orange !important;
    background-color: $white !important;
  }
}

.smartphone-item__misc__name {
  margin-bottom: $spacing;
}

.smartphone-item__misc__price {
  position: relative;
  color: $c-gray-darkest;
  font-size: 1.3rem;

  &::after {
    @include center-xy;

    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: $c-orange;
    border-radius: 10px;

    .is-reverse & {
      background-color: $white;
    }
  }
}

// Highlighted
.smartphone-item.is-highlight {
  position: relative;
  z-index: 1;
  display: block;
  width: auto;
  height: auto;
  margin: $spacing * 3 0;

  @include mq('m') {
    margin: 0;
  }

  .smartphone-item__picture-outer {
    width: 20rem;
    height: 42rem;
    margin: 0 auto;

    @include mq('m') {
      width: 34rem;
      height: 56rem;
    }

    @include mq('xl') {
      width: inner-col(5, 3);
      height: 63rem;
    }

    // &::after {
    //   @include get-all-space;
    //   content: '';
    //   z-index: -1;
    //   top: 50%;
    //   left: 50%;
    //   display: block;
    //   width: 65%;
    //   height: 70%;
    //   box-shadow: 0 30px 45px 6px rgba($c-gray-darkest, 0.28);
    //   transform: translate(-50%, -50%);

    //   @include mq(l, xl) {
    //     height: 85%;
    //   }
    // }
  }

  .smartphone-item__title {
    @extend %text-center;

    margin-bottom: $spacing / 2;
    color: $c-gray-darkest;
  }

  .smartphone-item__misc {
    @extend %text-center;
    @include center-y;

    left: 50%;
    padding: $spacing;
    color: $c-light;
    background-color: $c-orange;
    border-radius: 2rem;
    transform: translate(15%, -50%);

    @include mq('m') {
      padding: $spacing * 1.5 $spacing;
    }

    @include mq(xl) {
      transform: translate(25%, -50%);
    }
  }

  .smartphone-item__misc__price {
    @extend %fw-bold;

    font-family: $ff-alt;
    font-size: 1.5rem;
    line-height: 1.3;

    &::after {
      background-color: $c-gray-darkest;
    }

    @include mq(m) {
      font-size: 1.6rem;
    }

    @include mq(l) {
      font-size: 1.8rem;
    }
  }

  .smartphone-item__misc__offer {
    margin-bottom: $spacing / 2;
    font-size: 2.8rem;
    line-height: 1.17;

    .is-reverse & {
      color: $c-orange !important;
    }

    @include mq(m) {
      margin-bottom: $spacing;
      font-size: 3.2rem;
    }

    @include mq(l) {
      font-size: 4.8rem;
    }
  }

  .smartphone-item__misc__promo__label {
    @extend %text-uppercase;
    @extend %fw-black;

    position: relative;
    margin-bottom: $spacing / 2;
    font-family: $ff-alt;
    font-size: 1rem;

    @include mq(m) {
      margin-bottom: $spacing;
    }

    &::before,
    &::after {
      @include center-y;

      content: '';
      width: 1rem;
      height: 0.2rem;
      background-color: rgba($white, 0.3);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .smartphone-item__misc__promo__monthly {
    @extend %fw-medium;

    color: $c-gray-darkest;
  }
}

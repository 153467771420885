
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
























































































.smartphones-hero,
[class*='smartphones-hero--'] {
  position: relative;
  margin-top: $spacing * 5;
  margin-bottom: $spacing * 2.5;

  @include mq(l) {
    display: flex;
    margin-top: $spacing * 10;
    margin-bottom: $spacing * 7.5;
  }
}

.smartphones-hero__device {
  position: relative;
  padding-top: $spacing * 5;

  ::v-deep .smartphone-item__misc {
    min-width: 13.5rem;
  }

  ::v-deep .smartphone-item.is-highlight .smartphone-item__misc__offer {
    margin-bottom: 0;
  }

  ::v-deep .smartphone-item.is-highlight .smartphone-item__picture-outer {
    &::after {
      @include center-x;

      max-width: 20rem;
    }
  }

  @include mq(l) {
    flex-shrink: 0;
    width: col(6);
    padding-top: $spacing * 7;

    ::v-deep .smartphone-item__misc {
      display: none;
    }
  }

  @include mq(xxl) {
    flex-shrink: 0;
    margin-left: col(1);
  }
}

.smartphones-hero__device__speedlines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.smartphones-hero__device__picture {
  &.is-highlight {
    margin: $spacing * 1.25 0 $spacing * 2;

    ::v-deep .smartphone-item__picture-outer::after {
      top: auto;
      bottom: 15px;
    }

    @include mq(l) {
      pointer-events: none;
    }
  }
}

.smartphones-hero__infos {
  [class*='smartphones-pricing--'][class*='--horizontal'] {
    margin: 0 auto $spacing * 0.75;

    ::v-deep .smartphones-pricing__data {
      border-color: $c-gray-lightest;
    }
  }

  @include mq(l) {
    width: 100%;
    padding-top: $spacing * 7;

    [class*='smartphones-pricing--'][class*='--horizontal'] {
      width: 100%;
      margin: 0 0 $spacing * 0.75;

      ::v-deep {
        .smartphones-pricing__data,
        .smartphones-pricing__subscription {
          padding-top: $spacing;
          padding-bottom: $spacing;
        }

        .smartphones-pricing__data {
          flex: 1;
        }

        .smartphones-pricing__subscription {
          width: inside-col(5, 2);
        }
      }
    }
  }

  @include mq(xxl) {
    [class*='smartphones-pricing--'][class*='--horizontal'] {
      width: 100%;
      // max-width: inside-col(5, 4);
      margin-bottom: $spacing * 0.5;
    }
  }
}

.smartphones-hero__infos__title {
  ::v-deep small {
    display: block;
  }

  @include mq($until: l) {
    @include center-x;

    top: 0;
    width: 100%;
    font-size: 3.6rem;
    line-height: 1.1;
    text-align: center;

    ::v-deep small {
      font-size: 2.8rem;
      line-height: 1.18;
    }
  }

  @include mq(l) {
    font-size: 7.2rem;
    line-height: 1.1;

    ::v-deep small {
      font-size: 4.8rem;
    }
  }
}

.smartphones-hero__infos__price {
  display: none;

  @include mq(l) {
    display: block;
    margin-bottom: $spacing * 1.25;
  }
}

.smartphones-hero__infos__price__original {
  display: block;
}

.smartphones-hero__infos__price__new {
  color: $c-orange;
}

.smartphones-hero__infos__pricing {
  @include mq(l) {
    border-top: 1px solid $c-gray-lightest;
    border-bottom: 1px solid $c-gray-lightest;
  }
}

.smartphones-hero__infos__store,
.smartphones-hero__infos__condition {
  max-width: 30rem;
  margin: 0 auto $spacing * 1.5;

  @include mq(s) {
    max-width: 32rem;
  }

  @include mq(l) {
    max-width: none;
  }
}

.smartphones-hero__infos__store {
  padding: $spacing $spacing * 1.5;
  border: 1px solid $c-pink-medium;
  border-radius: 8px;

  @include mq(l) {
    padding: $spacing * 2;
  }
}

.smartphones-hero__infos__store__icon {
  width: 2.3rem;
  height: 1.8rem;
  margin-bottom: $spacing * 0.25;
  fill: $c-pink-medium;

  @include mq(l) {
    width: 3.2rem;
    height: 2.5rem;
  }
}

.smartphones-hero__infos__store__title {
  margin: 0 0 $spacing;
}

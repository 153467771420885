.voo-universe-switcher {
  position: relative;
}

.voo-universe-switcher-inner {
  display: flex;
  align-items: center;
  height: 5rem;
}

.voo-universe-switcher__item {
  position: relative;

  &.is-active {
    pointer-events: none;
  }

  & + & {
    padding-left: $spacing;

    @include mq(l) {
      .voo-universe-switcher__item__link::after {
        @include center-y;

        content: '';
        left: -$spacing / 2;
        display: block;
        width: 2px;
        height: 80%;
        background-color: $c-light;
        opacity: 0.5;
      }
    }
  }
}

.voo-universe-switcher__item__link {
  position: relative;
  color: $c-light;
  font-family: $ff-alt;
  font-size: 1.1rem;
  text-decoration: none;
  opacity: 0.5;
  transition: opacity 0.3s $ease-out-quad;

  .is-active &,
  &:hover {
    opacity: 1;
  }

  span {
    @extend %text-uppercase;
    @extend %fw-bold;
  }

  @include mq('m') {
    letter-spacing: 1.2px;
  }
}

.voo-universe-switcher__item__tooltip {
  position: absolute;
  top: 3rem;
  left: 0;
  padding: 1rem 1.5rem;
  width: 30rem;
  display: flex;
  opacity: 0;
  visibility: hidden;
  color: $c-midnight-darker;
  font-family: $ff-default;
  font-size: 1.3rem;
  line-height: 1.7rem;
  letter-spacing: normal;
  font-weight: 400;
  background: $gray-lightest;
  border-radius: 10px;
  transition: opacity 0.2s $ease;

  &::after {
    bottom: 100%;
    left: 3rem;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $gray-lightest;
    border-width: 8px;
    margin-left: -8px;
  }

  @include mq('m') {
    .voo-universe-switcher__item__link:hover & {
      visibility: visible;
      opacity: 1;
    }
  }
}

// TBC not use anymore?
// .voo-universe-switcher__icon {
//   @include center-y;

//   right: $spacing;
//   align-items: center;
//   width: 1rem;
//   height: 1rem;
//   fill: $c-light;
//   pointer-events: none;

//   @include mq($until: 'l') {
//     transform: rotate(180deg);
//   }
// }

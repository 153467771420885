
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        















































































































































































































































.homepage-macaron,
[class*='homepage-macaron--'] {
  position: relative;
  user-select: none;
  transform: translateY(1%) scale(0);
  // margin-right: $wrapper-spacing-s;
  transition: transform 0.3s $ease-out-back;

  ::v-deep svg {
    cursor: pointer;
    fill: $white;
  }

  .is-closed & {
    transform: translateY(30%) scale(1);
  }
}

.homepage-macaron__link {
  @include get-all-space;

  z-index: 1;
}

.homepage-macaron-inner {
  position: relative;
  width: 12rem;
  height: 12rem;
  border-radius: 100%;

  @include mq(l) {
    width: 15rem;
    height: 15rem;
  }
}

.homepage-macaron__background {
  @include get-all-space;

  z-index: 1;
  background-color: $c-pink-medium;
  border-radius: 100%;
  opacity: 0;
  transition: 0.5s $ease-out-expo;

  .is-closed & {
    opacity: 1;
  }
}

.homepage-macaron__picture {
  position: relative;
  z-index: 9;
  top: 0;
  pointer-events: none;
  transform: scale(0.7);
  transition: transform 0.5s $ease-out-expo;
}

.homepage-macaron__text-outer {
  @include center-xy;

  z-index: 2;
  pointer-events: none;
}

.homepage-macaron__text-inner {
  width: 11rem;
  height: 11rem;
  transition: transform 0.5s $ease-out-expo;

  // .is-closed & {
  //   width: 13rem;
  //   height: 13rem;
  // }

  @include mq(l) {
    width: 13rem;
    height: 13rem;
  }
}

.homepage-macaron__text {
  width: 11rem;
  height: 11rem;
  transform-origin: center;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-timing-function: linear;

  // .is-closed & {
  //   width: 13rem;
  //   height: 13rem;
  // }

  @include mq(l) {
    width: 13rem;
    height: 13rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

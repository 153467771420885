/* stylelint-disable no-descending-specificity */

.voo-footer-secondary-menu {
  position: relative;
  color: $white;

  @include mq('m') {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-row-gap: 5rem;
    column-gap: 5rem;
    justify-items: stretch;
    align-items: stretch;
    margin-top: $spacing * 2.5;
  }

  @include mq('l') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include mq('xl') {
    width: col(8.5);
    margin-top: 0;
    padding-left: $spacing * 2.5;
  }
}

.voo-footer-secondary-menu__item,
[class*='voo-footer-secondary-menu__item--'] {
  // padding: $spacing * 0.75 0;
  border-bottom: 1px solid $c-anthracite-light;

  @include mq('m') {
    padding-top: 0;
    padding-bottom: $spacing * 2;
  }

  @include mq('m', 'l') {
    &:nth-child(odd) {
      grid-column: 1 / 3;
    }

    &:nth-child(even) {
      grid-column: 3 / 5;
    }
  }
}

.voo-footer-secondary-menu__item__content {
  @include mq($until: 'm') {
    flex: 1;
    order: 2;
    margin-left: 1rem;
  }
}

.voo-footer-secondary-menu__item__text {
  color: $c-nevada;
  font-size: 14px;
  font-weight: 400;

  a {
    color: inherit;
  }
}

.voo-footer-secondary-menu__item__form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 3px;
    left: 0;
    width: 100%;
    background: $c-blue-light-pale;
    opacity: 0.3;
    border-radius: 2px;
  }

  &-input {
    color: $c-blue-light-pale;
    background: none;
    border: none;
    width: 100%;
    padding-right: 20px;

    &::placeholder {
      font-weight: 400;
      font-size: 18px;
      opacity: 1;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px $c-blue-darker;
      -webkit-text-fill-color: $c-blue-light-pale;
    }
  }

  &-button {
    user-select: none;
    background: none;
    border: none;
    font-size: 17px;
    font-weight: 600;
    font-family: $ff-alt;
    transition: color 0.2s $ease-out-quart;

    &:hover {
      color: $c-pink-medium;
    }
  }
}

.voo-footer-secondary-menu__item__title {
  @extend %fw-bold;

  margin-bottom: $spacing;
  font-family: $ff-alt;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.voo-footer-secondary-menu__item__description {
  font-size: 1.4rem;
  line-height: 2rem;
}

.voo-footer-secondary-menu__item__list {
  @extend %list-nostyle;
}

.voo-footer-secondary-menu__item__list__item {
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  line-height: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

// SOCIAL
.voo-footer-secondary-menu__item--social {
  @extend %list-nostyle;

  display: flex;
  justify-content: center;
  grid-column: 1 / span 3;
  padding: $spacing * 0.75 0 0;
  border-bottom: 0;

  li {
    margin-right: $spacing / 2;

    @include mq('l') {
      margin-right: $spacing;
    }
  }

  li:not(:first-child) {
    margin-left: $spacing / 2;

    @include mq('l') {
      margin-left: $spacing;
    }
  }

  span {
    @extend %visually-hidden;
  }

  @include mq('m') {
    justify-content: flex-start;
    padding-top: 0;
  }
}

.voo-footer-secondary-menu__item__icon {
  width: 3rem;
}

// SHOP
.voo-footer-secondary-menu__item--shop {
  position: relative;
  padding: $spacing * 0.75 0;

  .voo-footer-secondary-menu__item__title {
    margin-bottom: $spacing * 0.25;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .voo-footer-secondary-menu__item__link {
    @include get-all-space;

    z-index: 9;
    cursor: pointer;
  }

  &:hover {
    [class*='voo-footer-secondary-menu__item__picture--'][class*='--pin'] {
      transform: translateY(-20%);
    }

    [class*='voo-footer-secondary-menu__item__picture--'][class*='--shadow'] {
      transform: translateY(30%);
    }
  }

  @include mq($until: 'm') {
    display: flex;
  }

  @include mq('m') {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  @include mq('l') {
    padding-bottom: $spacing * 2;
    border-bottom: 1px solid $c-anthracite-light;
  }
}

.voo-footer-secondary-menu__item__picture-outer {
  position: relative;
  display: block;
  width: 100%;
  max-width: 24rem;
  height: 18rem;
  margin-right: auto;
  margin-left: auto;

  @include mq($until: 'm') {
    flex: 0 0 100px;
    order: 0;
    width: 100px;
    height: 50px;
  }

  @include mq('m') {
    max-width: 14.2rem;
    height: 7.2rem;
    margin-top: 2rem;
    margin-left: 0;
  }
}

.voo-footer-secondary-menu__item__picture,
[class*='voo-footer-secondary-menu__item__picture--'] {
  @include image-fit(contain);

  z-index: 2;
  transition: transform 0.3s $ease-out-quad;
}

[class*='voo-footer-secondary-menu__item__picture--'][class*='--pin'] {
  z-index: 3;
  top: 0;
  right: 30%;
  left: auto;
  width: 15%;
  height: auto;
}

[class*='voo-footer-secondary-menu__item__picture--'][class*='--shadow'] {
  z-index: 1;
  transform: translateY(20%);
}

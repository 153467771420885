
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






























.price {
  margin: $spacing * 2 0 $spacing;
  display: flex;
  justify-content: center;
}

.price__card {
  ::v-deep {
    .product-card__inner {
      background-color: transparent;
      box-shadow: none;
    }

    .product-card__details {
      padding: 0;
    }
    .product-card__pricing {
      margin-bottom: 0;
    }

    .product-card__main,
    .product-card__cta {
      display: none;
    }
  }
}

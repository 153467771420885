// Import fonts
@import './typekit'; // sass-lint:disable-line no-url-domains

@font-face {
  font-family: zuny;
  src: url('/assets/fonts/zuny/value-sans-black-pro.otf') format('opentype');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: zuny;
  src: url('/assets/fonts/zuny/value-sans-bold-pro.woff2') format('woff2'),
    url('/assets/fonts/zuny/value-sans-bold-pro.woff') format('woff'),
    url('/assets/fonts/zuny/value-sans-bold-pro.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: zuny;
  src: url('/assets/fonts/zuny/value-sans-medium-pro.woff2') format('woff2'),
    url('/assets/fonts/zuny/value-sans-medium-pro.woff') format('woff'),
    url('/assets/fonts/zuny/value-sans-medium-pro.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: zuny;
  src: url('/assets/fonts/zuny/value-sans-regular-pro.woff2') format('woff2'),
    url('/assets/fonts/zuny/value-sans-regular-pro.woff') format('woff'),
    url('/assets/fonts/zuny/value-sans-regular-pro.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

html {
  font-size: calc(1em * 0.625);
}

body {
  color: $c-gray-darkest;
  font: $body-text-s $ff-default;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  line-height: 1.65;

  strong {
    @extend %fw-bold;
  }

  @include mq('m') {
    font-size: $body-text-m;
  }

  @include mq('l') {
    font-size: $body-text-l;
  }
}

.voo-legals {
  position: relative;
}

.voo-legals-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: col(12);
  margin: 0 auto;
  padding: $spacing * 1.5 0 $spacing * 3;
  font-family: $ff-alt;
  font-size: 1.4rem;
  line-height: 3.6rem;
  border-top: 1px solid $c-anthracite-light;

  .is-minimal & {
    border-top: 0;
  }

  @include mq('xl') {
    flex-direction: row;
    padding: $spacing * 3 0;
  }
}

.voo-legals__logo {
  position: relative;
  width: 6rem;
  height: 3rem;
  margin-bottom: $spacing * 0.5;

  img {
    @include image-fit(contain);
  }

  @include mq('m') {
    margin-bottom: $spacing;
  }

  @include mq('xl') {
    margin-bottom: 0;
  }
}

.voo-legals__copyright {
  margin-bottom: $spacing * 0.5;
  color: $gray-medium;

  @include mq('m') {
    margin-bottom: $spacing;
  }

  @include mq('xl') {
    margin-bottom: 0;
  }
}

.voo-legals__list {
  @extend %list-nostyle;

  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2rem;

  @include mq('m') {
    flex-direction: row;
    line-height: 3.6rem;
  }

  @include mq('xl') {
    justify-content: flex-end;
  }
}

.voo-legals__item {
  @include mq($until: 'm') {
    margin: $spacing / 8;
  }

  @include mq('m') {
    & + & {
      margin-left: $spacing;
    }
  }
}

.voo-legals__item__link {
  color: $gray-medium;
  text-decoration: none;
  transition: color 0.2s $ease-out-quart;

  &:hover {
    color: $c-light;
  }
}

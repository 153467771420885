/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.voo-submenu-panel {
  position: fixed;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: col(12);
  height: 100%;
  margin: 0 auto;
  background-color: $white;

  [data-template='voo-for-employee'] &,
  [data-scheme='hidden'] & {
    @include mq(l) {
      margin-top: $spacing * 2.5;
    }
  }

  @include mq('nav') {
    flex-wrap: initial;
    justify-content: initial;
    padding-top: $spacing;
    padding-bottom: $spacing / 2;
  }
}

.voo-submenu-panel__list {
  width: 100%;
  height: 100%;
  margin-top: 7vh !important;
}

.voo-submenu-panel__list__item,
[class*='voo-submenu-panel__list__item--'] {
  position: relative;

  // Modifiers
  &[class*='--mobile'] {
    @include mq(l) {
      display: none;
    }
  }

  > a {
    @extend %fw-bold;

    position: relative;
    color: $c-blue-dark;
    text-decoration: none;
    transition: color 0.1s $ease-out;

    &:hover {
      color: $c-pink-medium !important;
    }

    // .voo-header.is-light & {
    //   @include mq(l) {
    //     color: $white;
    //   }
    // }

    // [data-template='blog'] &,
    [data-scheme='dark'] &,
    [data-scheme='transparent'] &,
    [data-template='employee-advocacy'] &,
    .voo-app--be.chrome-light & {
      @include mq(l) {
        color: $white !important;

        &.router-link-active {
          color: $c-pink-medium !important;
        }

        &:hover {
          color: $c-pink-medium !important;
        }
      }
    }

    [data-scheme='transparent'] & {
      .voo-header:not(.menu-open) {
        color: $white !important;
        text-shadow: 0 0 5px #0000003b;

        &:hover {
          color: $c-pink-medium !important;
        }
      }
    }

    &.router-link-exact-active {
      pointer-events: none;
    }

    &.router-link-active {
      color: $c-pink-medium !important;
    }

    &.is-page {
      color: $c-blue-dark !important;

      &::after {
        content: none;
      }
    }
  }

  @include mq($until: 'l') {
    font-size: 2.4rem;
  }

  @include mq('l') {
    & + & {
      margin-left: $spacing * 3.5;
    }
  }
}

.voo-submenu-panel__list__item__link {
  cursor: pointer;

  @include mq($until: l) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh 4vh;
  }

  @include mq(l) {
    &.voo-menu__no-link {
      display: flex;
      gap: 2rem;
      align-items: center;
    }
  }
}

.voo-submenu-panel__list__item__backto {
  @extend %button-nostyle;

  cursor: pointer;

  @include mq($until: l) {
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
    gap: 2rem;
    padding: 2vh 4vh;
    font-size: 2.4rem;
  }

  @include mq(l) {
    display: none;
  }

  .voo-submenu-panel__list__item__arrow {
    transform: rotate(180deg);
    fill: $c-blue-lightest;
  }
}

.voo-submenu-panel__list__item__arrow {
  fill: $c-blue-dark;
  width: 1.5rem;
  height: 1.5rem;

  @include mq('l') {
    display: none;

    .voo-menu__no-link & {
      display: block;
      width: 0.9rem;
      height: 0.9rem;
      transform: rotate(90deg);
    }
  }
}

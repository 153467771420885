.bolia,
.entertain,
.tv-hub,
.tv-universe,
.tv-universe-sport,
.tv-extra,
.voo-tv-plus,
.channels {
  // :( https://git.epic.net/voo/website-v3/track/-/issues/376
  .flickity-enabled,
  .flickity-slider,
  .flickity-viewport,
  [data-slide] {
    outline: none !important;
    outline-color: transparent !important;

    &:focus,
    &:focus-visible,
    &.is-selected {
      outline: none !important;
    }
  }

  ol[class*='slidy-nav'],
  .flickity-page-dots {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style-type: none;
  }

  .no-slider ~ ol[class*='slidy-nav'],
  .no-slider ~ [class*='__slidy-controls'] {
    display: none;
  }

  [class*='slidy-nav__item'] button,
  .flickity-page-dots .dot {
    @extend %button-nostyle;

    position: relative;
    display: block;
    width: 2rem;
    height: 2rem;
    font-size: 0;
    cursor: pointer;

    &::before {
      @include center-xy;

      content: '';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background: $c-blue-pale;
      border-radius: 50%;
    }

    &:focus-visible {
      outline: 2px solid $c-blue-pale;
      outline-offset: 5px;
    }

    span {
      @extend %visually-hidden;
    }

    .is-active &::before,
    &.is-selected::before {
      background: $c-pink-medium;
    }

    @include mq(l) {
      width: 3.2rem;
      height: 4.6rem;

      &::before {
        width: 0.822rem;
        height: 0.822rem;
      }
    }
  }

  [class*='slidy-nav__item'].is-active button::before {
    background: $c-pink-medium;
  }

  [class*='slidy-controls__item--'] {
    @extend %button-nostyle;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4.6rem;
    height: 4.6rem;
    color: $c-white-dark;
    border: 2px solid $c-white-dark;
    border-radius: 50%;
    transform: rotate(90deg);
    transition: opacity 0.2s;

    path,
    svg {
      fill: currentcolor;
    }

    &[class*='--next'] {
      margin-left: $spacing * 0.5;
      transform: rotate(-90deg);
    }

    &.is-disabled,
    &[disabled],
    &:active {
      opacity: 0.2;
    }

    &.is-disabled,
    &[disabled] {
      transition-duration: 0.5s;
    }

    &:focus-visible {
      outline: 2px solid $c-blue-pale;
      outline-offset: 5px;
    }
  }
}

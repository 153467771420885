
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































































.price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -2rem;
  font-family: $ff-alt;
  line-height: 1;
}

.price__original {
  @extend %fw-medium;

  position: relative;
  margin-right: 1rem;
  color: $white;
  font-size: 1.3rem;

  .table-content__header__main & {
    color: $black;
  }
}

.price__discount {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .has-advantage & {
    ::v-deep {
      .price__main,
      .price__stack__decimal {
        color: $c-mandarine;
      }
    }
  }

  .is-original & {
    ::v-deep {
      .price__main,
      .price__stack__decimal {
        color: $c-anthracite-light;
      }
    }
  }
}

.price__main,
.price__stack {
  color: $c-anthracite-light;
  color: $c-mandarine;

  .is-original & {
    color: $c-anthracite-light;
  }
}

.price__main {
  @extend %fw-bold;

  width: 8rem;
  font-size: 4.8rem;
  text-align: right;

  @include mq(m) {
    line-height: 1;
  }
}

.price__stack {
  margin-bottom: 0.5rem;
  margin-left: 0.3rem;
}

.price__stack__decimal,
.price__stack__months {
  display: block;
  line-height: 1;
  text-align: left;

  .is-zuny & {
    font-family: $ff-zuny;
    color: $c-midnight-darkest;
  }
}

.price__stack__decimal {
  @extend %fw-medium;

  margin-bottom: 0.2rem;
  font-size: 1.5rem;
}

.price__stack__months {
  @extend %fw-bold;

  color: $white;
  font-size: 1.8rem;
}

.price__promo {
  @extend %btn-tag;

  padding: 0.65rem 1rem 0.6rem;
  color: $white;
  font-size: 1rem;
  transform: translateX(1.5rem);

  .is-original & {
    display: none;
  }
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























.wysiwyg__content {
  p {
    margin: 0;
  }

  p + p {
    margin-top: $spacing * 1.5;
  }

  h2,
  h3,
  h4 {
    margin-top: $spacing * 4;
    margin-bottom: $spacing * 1.5;
  }
}

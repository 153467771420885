/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.voo-submenu-dropdown {
  @extend %box-shadow;

  position: absolute;
  bottom: 0;
  left: 50%;
  width: fit-content;
  padding: $spacing $spacing * 1.5;
  background-color: $white;
  border-radius: 1rem;
  opacity: 0;
  transform: translate(-50%, 110%);
  transition: 0.2s ease-out;
  pointer-events: none;

  .voo-menu__no-link:hover & {
    opacity: 1;
    pointer-events: initial;
    transform: translate(-50%, 100%);
  }

  &::after {
    @include center-x;

    content: '';
    z-index: -1;
    top: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: $white;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.voo-submenu-dropdown__list {
  @extend %list-nostyle;

  position: relative;
  display: flex;
  gap: $spacing * 0.5;
  flex-flow: column nowrap;
  outline: none;
}

.voo-submenu-dropdown__list__item,
[class*='voo-submenu-dropdown__list__item--'] {
  position: relative;
  flex-shrink: 0;

  a {
    @extend %fw-medium;

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing * 0.75;
    color: $c-blue-medium;
    font-size: 1.4rem;
    text-decoration: none;
    white-space: nowrap;
    transition: color 0.1s $ease-out;

    &:hover {
      color: $c-pink-medium !important;
    }
  }

  & + & {
    &::after {
      content: '';
      position: absolute;
      top: -0.5rem;
      left: 50%;
      display: block;
      width: 50%;
      height: 1px;
      background-color: rgba($c-blue-light-pale, 0.5);
      transform: translateX(-50%);
      pointer-events: none;
    }
  }
}

.voo-submenu-dropdown__list__item__arrow {
  fill: $c-blue-dark;
  width: 0.9rem;
  height: 0.9rem;
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






















































































































































.popup,
[class*='popup--'] {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.popup__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($c-midnight-darker, 0.8);
  backdrop-filter: blur(5px);
}

.popup__panel {
  @include center-xy;

  z-index: 10;
}

.popup__panel__close {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  padding: 1rem;
  background: transparent;
  border: 0;

  ::v-deep svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: $c-gray-darkest;
  }

  .video-popup-outer & {
    display: none;
  }

  @include mq($until: l) {
    z-index: 99;
  }
}

// To have a better control over styling,
// We hide the default close button and use another one directly within HubTV popup.
[data-popup-name='hub-tv'] {
  .popup__bg,
  .popup__panel__close {
    display: none;
  }

  @include mq(l) {
    .popup__bg {
      display: block;
      background: transparent;
      backdrop-filter: blur(10px);
    }
  }
}

[data-popup-name='contact-home'],
[data-popup-name='order-by-tel'] {
  @include mq($until: m) {
    .popup__panel {
      width: calc(100% - 4.6rem);
    }
  }
}

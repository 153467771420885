
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






























































.smartphones-pricing,
[class*='smartphones-pricing--'] {
  margin-bottom: $spacing;

  &[class*='--horizontal'] {
    display: flex;
    width: 20rem;
    margin: 0 auto;
  }

  @include mq(l) {
    display: flex;
    margin: 0 auto $spacing;
  }
}

.smartphones-pricing__block {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 50%;
  margin: 0 auto;
  padding: $spacing * 0.5 0;
  text-align: center;

  [class*='smartphones-pricing--'][class*='--horizontal'] & {
    text-align: left;
  }

  @include mq(l) {
    width: auto;
    padding: 0 $spacing * 0.5;
    text-align: left;
  }
}

.smartphones-pricing__data {
  border-bottom: 1px solid $c-blue-dark;

  [class*='smartphones-pricing--'][class*='--horizontal'] & {
    border-right: 1px solid $c-blue-dark;
    border-bottom: 0;
  }

  @include mq(l) {
    border-right: 1px solid $c-blue-dark;
    border-bottom: 0;
  }
}

.smartphones-pricing__subscription {
  [class*='smartphones-pricing--'][class*='--horizontal'] & {
    padding-left: $spacing * 1.5;
  }
}

.smartphones-pricing__block__label {
  @extend %fw-black;

  flex: 1;
  margin-bottom: $spacing * 0.5;
  color: $c-blue-pale;

  @include mq(l) {
    margin-bottom: $spacing * 0.25;
  }
}

.smartphones-pricing__block__data {
  @extend %fw-black;

  color: $c-gray-darkest;
  font-size: 1.5rem;
  line-height: 1.6;

  @include mq(l) {
    font-size: 1.8rem;
  }
}

.smartphones-pricing__block__price {
  color: $c-orange;
  font-size: 0.9rem;
  line-height: 1;

  ::v-deep strong {
    font-size: 1.5rem;
  }

  @include mq(l) {
    font-size: 1.3rem;

    ::v-deep strong {
      font-size: 1.8rem;
    }
  }
}

.smartphones-pricing__block__headline {
  font-size: 0.9rem;
  line-height: 1.3;

  @include mq(l) {
    font-size: 1.3rem;
    line-height: 1.8;
  }
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


















































































































































































































































































































































































































































































































































$rotation: 33deg;

.speedlines {
  position: absolute;
  top: 0;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  width: -moz-available;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s $ease;
  pointer-events: none;

  &.is-visible {
    opacity: 1;
  }
}

.speedlines__spacer {
  width: auto;
  height: 100%;
}

.speedlines__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.speedlines__preload-pic {
  @extend %visually-hidden;
}

.speedlines__item,
[class*='speedlines__item--'] {
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 20px);
  padding: 70px 20px;
  background-color: $c-pink-medium;
  border-radius: 2in;
  transform: rotate($rotation);
  transform-origin: 50% 50%;
  will-change: transform;
}

// Order matters basic colors before variations
[class*='speedlines__item--'][class*='--pink'] {
  background-color: $c-pink-medium;
}

[class*='speedlines__item--'][class*='--purple'] {
  background-color: $c-purple;
}

[class*='speedlines__item--'][class*='--orange'] {
  background-color: $c-orange;
}

[class*='speedlines__item--'][class*='--pink-light'] {
  background-color: $c-pink-light;
}

[class*='speedlines__item--'][class*='--pink-lightest'] {
  background-color: $c-pink-lightest;
}

[class*='speedlines__item--'][class*='--blue-medium'] {
  background-color: $c-blue-medium;
}

[class*='speedlines__item--'][class*='--blue-dark'] {
  background-color: $c-blue-dark;
}

[class*='speedlines__item--'][class*='--blue-indigo'] {
  background-color: $c-blue-indigo;
}

[class*='speedlines__item--'][class*='--blue-metal'] {
  background-color: $c-blue-metal;
}

[class*='speedlines__item--'][class*='--light'] {
  background-color: $c-light;
}

[class*='speedlines__item--'][class*='--cyan'] {
  background-color: $c-cyan-contrast;
}

[class*='speedlines__item--'][class*='--proximus-variant'] {
  background-color: $c-proximus-variant;
}

[class*='speedlines__item--'][class*='--gradient'] {
  background-color: transparent;
  filter: blur(3px);
}

[class*='speedlines__item--'][class*='--gradient-blue'] {
  background: radial-gradient($c-light-blue, transparent 75%);
}

[class*='speedlines__item--'][class*='--gradient-pink'] {
  background: radial-gradient($c-pink-medium, transparent 75%);
}

[class*='speedlines__item--'][class*='--gradient-pink-light'] {
  background: radial-gradient(rgba($c-pink-light, 0.8), transparent 75%);
}

.speedlines__marker {
  position: absolute;
  z-index: 99;
  width: 5px;
  height: 5px;
  background-color: lime;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

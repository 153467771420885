
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        















































.price-label,
[class*='price-label--'] {
  margin-bottom: 1rem;
  color: $c-anthracite-light;
  font-family: $ff-alt;
  font-size: 1.3rem;
  text-align: center;
}

.price-label__price,
.price-label__from,
.price-label__unit {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.price-label__price {
  padding: 0 0.25rem;
  position: relative;

  &::after {
    content: '';
    height: 4px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    background-color: $c-anthracite-light;
    opacity: 0.7;
    border-radius: 6px;
  }

  .is-original & {
    display: none;
  }
}

.price-label__unit {
  .is-original & {
    display: none;
  }
}

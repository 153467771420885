.footer {
  margin-top: 100vh;

  main + &,
  div + & {
    margin-top: 0;
  }
}

// Dev
.is-empty {
  &::before {
    content: '';
    display: block;
    height: 0;
    // padding-top: 100%;
  }
}

.speedlines {
  // position: relative !important;
  &::before {
    content: '';
    display: block;
    height: 0;
    // padding-top: 100%;
  }
}

// remove horizontal scroll
// DO NOT APPLY TO BUSINESS!
// Do not apply to hub sport. Otherwise it will break mobile sticky CTA.
// Do not apply to hub general either. Otherwise it will break the scrolling channels
.voo-app--be {
  overflow: hidden;

  [data-template='configurator'] &,
  [data-template='comparator'] &,
  [data-template='tv'] &,
  [data-template='homepage'] &,
  [data-template='hub-tv-robbie'] &,
  [data-template='net'] & {
    overflow: visible;
  }
}

// OneTrust hack
body .cookie-policy#ot-sdk-cookie-policy,
body .optanon-category-C0004 {
  display: none;
}

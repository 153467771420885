
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























































































































































































































































.homepage-sticky-outer {
  position: fixed;
  z-index: 99;
  right: -2rem;
  bottom: $spacing;
  left: 3rem;

  @include mq(m) {
    right: 0;
    bottom: $spacing * 4;
    left: auto;
  }

  &.is-hidden {
    z-index: -1;
  }

  &.is-closed {
    bottom: $spacing * 3;
    left: auto;

    @include mq(m) {
      bottom: $spacing * 6;
    }
  }
}

.homepage-sticky {
  display: flex;
  align-items: center;
  padding-right: 5rem;
  opacity: 0;
  transition: 0.3s $ease-out-quad;

  .is-closed & {
    &.is-minimized {
      transform: translate(30px, 40px) scale(0.5) !important;

      @include mq(m) {
        transform: translate(30px, 100px) scale(0.5) !important;
      }
    }
  }

  &.is-minimized {
    @include mq(m) {
      transform: translate(calc(100% - 100px), 30px) !important;

      &:hover {
        @include mq(m) {
          transform: translate(0, 30px) !important;
        }
      }
    }
  }

  @include mq(m) {
    flex-direction: row;
    transform: translateY(30%);
  }
}

.homepage-sticky__background {
  @include get-all-space;

  z-index: -1;

  width: calc(100% - 5rem);
  background-color: $white;
  border-radius: 1rem;
  transition: 0.3s $ease-out-quad;
  box-shadow: $box-shadow-lvl-1;

  .is-closed & {
    background-color: $c-mandarine;
    border-radius: 10rem;
    opacity: 0;
  }
}

.homepage-sticky__close {
  @extend %button-nostyle;

  position: absolute;
  z-index: 9;
  top: 0;
  right: 5rem;
  display: block;
  width: auto;
  padding: 1rem;
}

.homepage-sticky__label {
  @extend %fw-bold;
  @extend %text-uppercase;

  position: absolute;
  top: 0;
  left: 2rem;
  display: inline-block;
  width: auto;
  padding: 0.6rem 0.8rem 0.5rem;
  color: $white;
  font-size: 1.1rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  background-color: $c-gray-darkest;
  border-radius: 0.3rem;
  transform: translate(0, -50%);

  &[class*='label--'][class*='--orange'] {
    background-color: $c-orange;
  }

  &[class*='label--'][class*='--pink'] {
    background-color: $c-pink-medium;
  }

  &[class*='label--'][class*='--cyan'] {
    background-color: $c-cyan-contrast;
  }

  &[class*='label--'][class*='--white'] {
    color: $c-blue-dark;
    background-color: $c-light;
  }
}

.homepage-sticky__macaron {
  ::v-deep {
    .homepage-macaron-inner {
      position: relative;
      z-index: 1;
      width: 10rem;
      height: 10rem;
      margin: 0 auto;
      transition: 0.3s $ease-out-quad;

      .is-closed & {
        width: 12rem;
        height: 12rem;

        @include mq(l) {
          width: 15rem;
          height: 15rem;
        }
      }
    }

    .homepage-macaron__text-outer {
      display: none;
      transition: 0.3s $ease-out-quad;

      .is-closed & {
        display: block;
      }
    }
  }
}

.homepage-sticky__link {
  @include get-all-space;

  z-index: 1;

  span {
    @extend %visually-hidden;
  }
}

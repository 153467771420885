@import './slidy';

.tp-dfwv {
  position: fixed !important;
  z-index: 1000; // Above header
}

// Robbie specific style could temporarly be scoped here
// Using body's data-template attribute instead of .robbie-hub-tv (for example) because
// popup content are injected outside of the page content thus scoped styles won't be applied...
.entertain,
.tv-hub,
.tv-universe,
.tv-universe-sport,
.tv-extra,
.voo-tv-plus,
[data-template^='tv']:not([data-template='tv-vod']) .voo-app--be {
  // Typography
  [class*='robbie-tv-title'] {
    // prettier-ignore
    @include fluid(font-size, (s: 28px, l: 54px));

    margin-top: 0;
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 1.17;

    &[class*='--center'] {
      text-align: center;
    }

    strong,
    b {
      /* stylelint-disable-next-line declaration-no-important */
      font-weight: 600 !important;
    }
  }

  [class*='btn--'][class*='--big'] {
    height: auto;
    padding: $spacing $spacing * 1.5;

    .btn__icon {
      margin-left: $spacing * 0.5;

      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  .robbie-title-medium {
    // prettier-ignore
    @include fluid(font-size, (s: 22px, l: 44px));

    margin: 0;
    font-weight: 700;
    line-height: 1.2;
  }

  .robbie-title-small {
    // prettier-ignore
    @include fluid(font-size, (s: 22px, l: 30px));

    margin: 0;
    font-weight: 700;
    line-height: 1.2;
  }
}

// overflow-hidden break the mobile sport sticky CTA
[data-template='tv-universe-sport'] {
  .voo-app--be {
    overflow: visible;
  }
}

.abstract-background {
  position: relative;
  min-height: 100vh;
  background: $c-promo-card;

  &::before,
  &::after {
    @include get-all-space;

    content: '';
    display: block;
    pointer-events: none;
  }

  &::before {
    background: radial-gradient(
      70.77% 37.46% at 121.79% 106.52%,
      rgba(12, 186, 186, 0.42) 0%,
      rgba(12, 186, 186, 0.14) 49.3%,
      rgba(12, 186, 186, 0) 100%
    );
  }

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      radial-gradient(
        143.46% 103.5% at -2.56% 30.21%,
        rgba(12, 186, 186, 0.42) 0%,
        rgba(12, 186, 186, 0.14) 49.3%,
        rgba(12, 186, 186, 0) 100%
      );
  }
}

.abstract-background-opacity {
  position: relative;
  background: rgba($c-promo-card, 0.1);

  &::before,
  &::after {
    @include get-all-space;

    content: '';
    display: block;
    opacity: 0.2;
    pointer-events: none;
  }

  &::before {
    background: radial-gradient(
      70.77% 37.46% at 121.79% 106.52%,
      rgba(12, 186, 186, 0.42) 0%,
      rgba(12, 186, 186, 0.14) 49.3%,
      rgba(12, 186, 186, 0) 100%
    );
  }

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      radial-gradient(
        143.46% 103.5% at -2.56% 30.21%,
        rgba(12, 186, 186, 0.42) 0%,
        rgba(12, 186, 186, 0.14) 49.3%,
        rgba(12, 186, 186, 0) 100%
      );
  }
}

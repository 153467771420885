
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
















































// Scoped is causing trouble in assistant results
/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.btn,
[class*='btn--'] {
  @extend %fw-bold;

  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -0.3rem;
  padding: $spacing * 0.5 $spacing * 0.75;
  color: $white;
  font-family: $ff-alt;
  text-decoration: none;
  background-color: $c-green-light;
  border: 0;
  border-radius: 50px;
  transition: background-color 0.3s $ease;

  &.disabled,
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &[class*='--zuny'] {
    background-color: $c-zuny-lighter;
  }

  &.is-hidden {
    display: none;
  }

  &[class*='--big'] {
    // height: 4.6rem;
    padding: $spacing $spacing * 1.1;
  }

  &:hover,
  [class*='hero__slides__item']:hover &,
  [class*='product-tv__slider__item']:hover & {
    background-color: darken($c-green-light, 5);

    &[class*='--outline'] {
      background-color: $c-anthracite-light;
    }
  }

  &[class*='--zuny']:hover {
    background-color: $c-zuny-light;
  }

  &[class*='--outline'] {
    background-color: $white;
    border: 0.2rem solid $c-anthracite-light;
  }

  &[class*='--icon-left'] {
    flex-direction: row-reverse;
  }

  @include mq(m) {
    // height: 4.2rem;
    padding: $spacing * 0.75 $spacing;

    &[class*='--big'] {
      height: 5.4rem;
      padding: 0 $spacing * 1.5;
    }
  }

  @include mq(xl) {
    // height: 4.6rem;

    &[class*='--big'] {
      height: 6rem;
      padding: 0 $spacing * 1.5;
    }
  }
}

.btn__link {
  display: block;
  padding: 0;
  background: transparent;
  border: 0;
}

.btn__link__title,
.btn__link__subtitle {
  @extend %fw-bold;

  display: block;
  color: $white;
  font-family: $ff-alt;
  line-height: 1;
  transition: color 0.3s $ease;

  [class*='btn--'][class*='--outline'] & {
    color: $c-anthracite-light;
  }

  [class*='btn--'][class*='--outline']:hover & {
    color: $white;
  }
}

.btn__link__headline {
  @extend %fw-bold;

  display: block;
  color: $white;
  font-size: 1rem;
  line-height: 1.3;
  text-align: left;
  text-transform: uppercase;
  transition: color 0.3s $ease;

  [class*='btn--'][class*='--outline'] & {
    color: $c-anthracite-light;
  }

  [class*='btn--'][class*='--outline']:hover & {
    color: $white;
  }
}

.btn__link__title {
  font-size: 1.5rem;

  [class*='btn--'][class*='--big'] & {
    font-size: 1.8rem;
  }

  @include mq(m) {
    font-size: 1.6rem;

    [class*='btn--'][class*='--big'] & {
      font-size: 2.2rem;
    }
  }

  @include mq(xl) {
    font-size: 1.8rem;

    [class*='btn--'][class*='--big'] & {
      font-size: 2.4rem;
    }
  }
}

.btn__icon {
  margin-left: $spacing;
  line-height: 0.5;
  transition: transform 0.3s $ease;

  .btn:hover &,
  [class*='hero__slides__item']:hover &,
  [class*='product-tv__slider__item']:hover & {
    transform: translateX(0.7rem);
  }

  [class*='btn--'][class*='--big'] & {
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  [class*='btn--'][class*='--outline'] & {
    svg {
      fill: $c-anthracite-light !important;
    }
  }

  [class*='btn--'][class*='--outline']:hover & {
    svg {
      fill: $white !important;
    }
  }

  [class*='btn--'][class*='--no-icon'] & {
    display: none;
  }

  [class*='btn--'][class*='--icon-left'] & {
    margin-right: $spacing * 0.5;
    margin-left: 0;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: $white;
    }
  }

  [class*='btn--'][class*='--icon-left']:hover & {
    transform: none;
  }

  svg {
    width: 0.5em;
    height: 0.5em;
    fill: $white;
    transition: fill 0.3s $ease;

    .contact-item:nth-child(2) & {
      width: 1em;
      height: 1em;
    }
  }
}

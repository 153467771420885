
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
// Scoped is causing trouble in assistant results
.btn__link {
  display: block;
  padding: 0;
  background: transparent;
  border: 0;

  &.loading {
    opacity: 0;
  }
}

.btn__link__title,
.btn__link__subtitle {
  @extend %fw-bold;

  display: block;
  color: $white;
  font-family: $ff-alt;
  line-height: 1;
  transition: color 0.3s $ease;

  [class*='btn--'][class*='--outline'] & {
    color: $c-anthracite-light;
  }

  [class*='btn--'][class*='--outline']:hover & {
    color: $white;
  }
}

.btn__link__headline {
  @extend %fw-bold;

  display: block;
  color: $white;
  font-size: 1rem;
  line-height: 1.3;
  text-align: left;
  text-transform: uppercase;
  transition: color 0.3s $ease;

  [class*='btn--'][class*='--outline'] & {
    color: $c-anthracite-light;
  }

  [class*='btn--'][class*='--outline']:hover & {
    color: $white;
  }
}

.btn__link__title {
  font-size: 1.5rem;

  [class*='btn--'][class*='--big'] & {
    font-size: 1.8rem;
  }

  @include mq(m) {
    font-size: 1.6rem;

    [class*='btn--'][class*='--big'] & {
      font-size: 2.2rem;
    }
  }

  @include mq(xl) {
    font-size: 1.8rem;

    [class*='btn--'][class*='--big'] & {
      font-size: 2.4rem;
    }
  }
}

.btn__icon {
  margin-left: $spacing;
  line-height: 0.5;
  transition: transform 0.3s $ease;

  .btn:hover &,
  [class*='hero__slides__item']:hover &,
  [class*='product-tv__slider__item']:hover & {
    transform: translateX(0.7rem);
  }

  [class*='btn--'][class*='--big'] & {
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  [class*='btn--'][class*='--outline'] & {
    svg {
      fill: $c-anthracite-light !important;
    }
  }

  [class*='btn--'][class*='--outline']:hover & {
    svg {
      fill: $white !important;
    }
  }

  [class*='btn--'][class*='--no-icon'] & {
    display: none;
  }

  [class*='btn--'][class*='--icon-left'] & {
    margin-right: $spacing * 0.5;
    margin-left: 0;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: $white;
    }
  }

  [class*='btn--'][class*='--icon-left']:hover & {
    transform: none;
  }

  svg {
    width: 0.5em;
    height: 0.5em;
    fill: $white;
    transition: fill 0.3s $ease;

    .contact-item:nth-child(2) & {
      width: 1em;
      height: 1em;
    }

    &.stroke-only {
      fill: none;
      stroke: currentcolor;
    }
  }

  &.loading {
    opacity: 0;
    pointer-events: none;
  }
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.25em;
  height: 1.25em;
  transform: translate(-50%, -50%);
}

.spinner {
  display: block;
}

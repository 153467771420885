.voo-footer-primary-menu {
  position: relative;
  color: $white;

  @include mq($until: 'xl') {
    padding-bottom: $spacing * 2;
    border-bottom: 1px solid $c-anthracite-light;
  }

  @include mq('xl') {
    width: col(3.5);
    padding-right: $spacing * 2.5;
    padding-bottom: 0;
    border-right: 1px solid $c-anthracite-light;
    border-bottom: 0;
  }
}

.voo-footer-primary-menu__title {
  @extend %fw-bold;

  margin-bottom: $spacing * 0.75;
  font-family: $ff-alt;
  font-size: 2.4rem;
  line-height: 2rem;

  @include mq('m') {
    margin-bottom: $spacing * 1.5;
    font-size: 3rem;
    line-height: 2.4rem;
  }

  @include mq('xl') {
    margin-bottom: $spacing;
  }
}

.voo-footer-primary-menu__list {
  @extend %list-nostyle;

  @include mq('m') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    justify-items: stretch;
    align-items: stretch;
  }

  @include mq('l') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include mq('xl') {
    grid-template-columns: 1fr;
  }
}

.voo-footer-primary-menu__item {
  margin-bottom: 0.6rem;
  font-size: 1.6rem;
  line-height: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq('xl') {
    margin-bottom: 1.2rem;
    font-size: 1.8rem;
  }
}

.voo-footer-primary-menu__item__link {
  color: $white;
  text-decoration: none;
  transition: color 0.2s $ease-out-quart;

  &:hover {
    color: $c-pink-medium;
  }
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






















.tips {
  position: relative;
  margin-top: $spacing * 2;
}

.tips-inner {
  position: relative;
  padding: $spacing * 2 inner-col(12, 1);

  @each $class, $color in $tips-schemes {
    .#{$class} & {
      background-color: $color;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 2px;
    height: 100%;

    @each $class, $color in $tips-schemes-hue {
      .#{$class} & {
        background-color: $color;
      }
    }
  }
}

.tips__title {
  display: flex;
  align-items: center;
  margin-top: $spacing / 2;
  margin-bottom: 0;

  @each $class, $color in $tips-schemes-hue {
    .#{$class} & {
      color: $color;
    }
  }
}

.tips__excerpt {
  a,
  a:visited {
    color: $c-pink-medium;
  }
}

.tips__icon {
  width: 4rem;
  height: 4rem;
  margin-right: $spacing / 2;

  @each $class, $color in $tips-schemes-hue {
    .#{$class} & {
      fill: $color;
    }
  }
}

//Avoid typekit FOUT
html {
  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

.is-locked {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

// :root {
//   --content-width: 100vw;
//   --column-number: #{$columns-number};
//   --spacer-margin: 40px;

//   @include mq('m') {
//     --spacer-margin: 140px;
//   }

//   @include mq('xxl') {
//     --spacer-margin: 240px;
//   }

//   @include mq('xxl') {
//      --content-width: #{$content-width};
//   }
// }

body {
  &.is-transitioning {
    overflow: hidden;
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

main {
  // DEV
  // padding-top: 7rem;

  // @include mq(l) {
  //   padding-top: 5rem;
  // }
}

::selection {
  background-color: $c-pink-medium;
  color: $white;
}

// NO SCROLL
.no-scroll {
  overflow-y: hidden;
  height: 100vh;
  padding-right: 15px;
}

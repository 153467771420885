
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






































































.price-difference {
  @extend %fw-medium;
  @include center-x;

  display: none;
  padding-bottom: $spacing * 0.25;
  font-family: $ff-alt;
  font-size: 1.3rem;
  text-transform: uppercase;
  white-space: nowrap;
  border-bottom: 0.1rem solid $c-blue-lightest;

  strong {
    display: inline-block;
    margin: 0 $spacing * 0.5;
    font-size: 1.7rem;
    transform: scale(1.3);
  }

  @include mq(l) {
    strong {
      font-size: 2rem;
    }
  }
}

.price-difference__decimals {
  margin-left: -4px;
  font-size: 60%;
}

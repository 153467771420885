
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.gallery {
  position: relative;
  width: 100%;

  .flickity-viewport {
    margin-bottom: $spacing * 3;
  }
}

.gallery__slider {
  display: flex;
  flex-shrink: 0;
  overflow: hidden;

  .flickity-button.next,
  .flickity-button.previous {
    display: inline-flex;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .flickity-button.previous {
    transform: translateX(-100%);
    right: 1rem;
  }
}

.gallery__slider__item {
  width: 100%;
  aspect-ratio: 16/10;
}

.gallery__slider__item__picture {
  @include image-fit;
}

.voo-logo {
  display: inline-block;
  will-change: transform;
  transition: fill 0.2s ease-out;

  .icon {
    fill: $c-pink-medium;

    .voo-app--business & {
      fill: $c-pink-dark;
    }
  }

  [data-template='blog-by'] &,
  [data-scheme='dark'] &,
  [data-scheme='hidden'] &,
  .voo-app--be.chrome-light & {
    @include mq(l) {
      .icon {
        fill: $white;
      }
    }
  }

  [data-scheme='transparent'] & {
    .icon {
      fill: $white;
    }
  }
}

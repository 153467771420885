
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
































.lead {
  margin-bottom: 0;
}

.chapo__img {
  width: 100%;
  margin-top: $spacing * 2;
  margin-bottom: $spacing * 2;
}

::v-deep p,
::v-deep strong {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 700 !important;
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        








































































































































































































































































































































































































































































































































.work-map-infowindow {
  max-width: 28rem;
}

.work-map-infowindow__title {
  margin-bottom: 1rem;
  padding-right: 3rem;
  font-size: 1.8rem;
  font-weight: 700;
}

.work-map-infowindow__dl {
  margin: 0;
  padding: 0;
}

.work-map-infowindow__details {
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.work-map-infowindow__details__title {
  font-weight: 700;
}

.work-map-infowindow__details__value {
  margin: 0;
  text-transform: capitalize;
}

.gm-style-iw-d + button[title] {
  transform: translate(-11px, 11px);
  opacity: 1;
}

.gm-style-iw-d + button[title] > span {
  transform: scale(2);
  background-color: $black;
}

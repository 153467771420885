
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
























.price-htva__mention {
  font-size: 0.8em;
  font-weight: 300;
  opacity: 0.7;
  line-height: 2;
}

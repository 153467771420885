
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        








































































.notfound-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  max-height: 80rem;
  padding-top: $spacing * 6;

  @include mq($until: l) {
    flex-direction: column-reverse;
    height: auto;
    margin-bottom: $spacing * 3;
  }
}

.notfound__content__headline {
  @extend %fw-bold;
  @extend %text-uppercase;

  font-size: 1.6rem;
}

.notfound__content__title {
  margin: 0;
  margin-top: $spacing;
}

.notfound__content__text {
  @extend %fw-bold;

  margin-top: $spacing / 2;
  font-family: $ff-alt;
}

.notfound__content__link {
  @extend %fw-bold;

  display: inline-block;
  margin-top: $spacing * 2;
  padding: $spacing / 2 $spacing;
  color: $white;
  font-family: $ff-alt;
  font-size: 2.4rem;
  text-decoration: none;
  background-color: $c-green-light;
  border: 0;
  border-radius: 50px;
  transition: background-color 0.3s $ease;

  &:hover {
    background-color: darken($c-green-light, 5);
  }
}

.notfound__speedlines {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  margin-top: $spacing * 6;

  @include mq($until: l) {
    position: relative;
    height: 45rem;
    margin-top: 0;
  }

  @include mq(l) {
    height: 65rem;
  }

  @include mq(xl) {
    right: col(1);
    bottom: $spacing * -3;
  }
}

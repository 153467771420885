
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        













































































































































































































































































































.services-list,
[class*='services-list--'] {
  @include bg-color;
  &.is-midnight-darkest {
    .services-list__intro *,
    .services-list__list__item * {
      color: white;
    }
  }
}

.services-list__inner {
  position: relative;
  padding-bottom: $spacing * 4;

  @include mq(xl) {
    display: flex;
    justify-content: space-between;
    padding-bottom: $spacing * 3 !important;
  }
}

.services-list__intro {
  @include mq(xl) {
    width: col(4);
  }
}

.services-list__intro__headline {
  @extend %fw-normal;

  margin-top: 0;
}

.services-list__title {
  margin-top: 0;
}

.services-list__intro__title {
  @extend %fw-normal;

  margin-top: 0;
}

.services-list__intro__text {
  font-size: 2.4rem;
  line-height: 3rem;
}

.services-list__lists {
  @include mq(xl) {
    width: col(7);
  }
}

.services-list__list,
[class*='services-list__list--'] {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq($until: l) {
    row-gap: $spacing * 2;
  }

  @include mq($until: xl) {
    margin-top: $spacing * 2;
  }

  @include mq(l) {
    row-gap: $spacing * 3;
  }
}

[class*='--expand'] {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;

  @include mq($until: xl) {
    margin-top: 0;
  }
}

.services-list__list__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: inner-col(12, 12);

  @include mq(s) {
    width: inner-col(12, 5);
  }

  @include mq(l) {
    margin-top: 0;
    //margin-bottom: $spacing * 3;
  }

  @include mq(xl) {
    width: inner-col(7, 3);
  }
}

.services-list__list__item__icon {
  width: 5rem;
  height: 5rem;
  fill: $c-pink-medium;

  svg {
    fill: $c-pink-medium;
  }
}

.services-list__list__item__title {
  margin-top: 2rem;
  margin-bottom: 1.2rem;
}

.services-list__list__item__btn {
  margin-top: $spacing * 1.5;
}

.services-list__list__item__btn + .link--pink {
  margin-top: $spacing;
}

.services-list__overlayer-trigger {
  margin-top: 2rem;
}

.services-list__list__item__links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $spacing / 2;

  a {
    margin-bottom: 0.5rem;
  }
}

.services-list__app {
  @include mq($until: 'm') {
    width: col(10);
    margin-top: $spacing;
  }
}

.services-list__app__title {
  @extend %fw-bold;

  margin-top: 0;
  margin-bottom: $spacing;
  font-size: 1.8rem;
  line-height: 1;

  @include mq('l') {
    margin-top: 1.5em;
  }
}

.services-list__app__list {
  display: flex;
  margin-bottom: $spacing * 2;
}

.services-list__app__item {
  position: relative;
  width: 100%;
  max-width: 13rem;
  height: 4rem;

  @include mq('m', 'xl') {
    width: 100%;
    max-width: 13rem;
    height: 4rem;
  }

  & + & {
    margin-left: $spacing / 2;
  }
}

.services-list__app__item__link {
  transition: opacity 0.3s $ease-out-quad;

  &:hover {
    opacity: 0.5;
  }
}

.services-list__app__item__picture {
  @include image-fit(contain);

  width: auto;
}

.services-list__more-outer {
  display: inline-flex;
  width: 100%;
}

.services-list__more {
  margin-top: $spacing * 3;
  margin-inline: auto;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .icon {
    margin-left: 0.5rem;
    width: 1.6rem;
    height: 1.6rem;
    fill: $c-pink-medium;
  }

  &:hover {
    .icon {
      fill: $black;
    }
  }
}

.services-list__speedlines {
  z-index: -1;
  top: unset;
  bottom: -2rem;
  left: 0;
  width: 60rem;
  height: 50%;

  @include mq($until: xl) {
    display: none;
  }
}

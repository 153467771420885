
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



































































.accordion__content {
  overflow: hidden;
}

.accordion__toggle {
  &:hover {
    cursor: pointer;
  }
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        











































.myvoo,
[class*='Myvoo--'] {
  position: relative;
  z-index: 1;
  padding: $spacing * 2.5 0 $spacing * 3;

  @include mq(m) {
    padding: $spacing * 3.5 0 $spacing * 4;
  }

  @include mq(l) {
    width: col(5);
    padding: $spacing * 5 0 $spacing * 6;
    padding-left: col(1);
  }
}

.myvoo__icon {
  width: 3.6rem;
  height: 3.6rem;
  fill: $c-pink-medium;
}

.myvoo__title {
  margin: $spacing / 2 0;
}

.myvoo__excerpt {
  margin: $spacing * 2 0;
}

.myvoo__btn-outer {
  margin-bottom: $spacing * 1.5;
}

.myvoo__link {
  position: relative;
  color: $c-gray-darkest;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $c-gray-darkest;
    transition: transform 0.3s $ease-out-quad;
    transform-origin: left;
    transform: scaleX(1);
  }

  &:hover {
    &::after {
      transform-origin: right;
      transform: scaleX(0);
    }
  }
}

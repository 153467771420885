.voo-help {
  display: flex;
  justify-content: center;
  align-items: center;
}

.voo-help__label,
[class*='voo-help__label--'] {
  @extend %fw-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 $spacing;
  color: $c-light;
  font-family: $ff-alt;
  font-size: 1.1rem;
  text-decoration: none;
}

.voo-help__label__icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: $spacing / 2;
  fill: $c-light;
}

[class*='voo-help__label--'][class*='--disconnected'] {
  .is-connected & {
    display: none;
  }
}

[class*='voo-help__label--'][class*='--connected'] {
  display: none;
  line-height: 1.2;

  @include mq($until: l) {
    justify-content: space-between;
    margin-top: $spacing;
    padding: 0;
    color: $black;

    svg {
      display: none;
    }
  }

  .is-connected & {
    display: flex;
  }

  .voo-menu.is-connected & {
    @include mq(l) {
      display: none;
    }
  }

  .voo-help__label__button {
    @extend %button-nostyle;
    margin-left: $spacing;
  }
}

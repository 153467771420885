.carousel {
  // Add this class to the carousel if its content has a drop-shadow.
  // It will add block padding to prevent box shadow from being clipped by the scroller
  // and compensate the additional padding by applying negative block margin.
  &.padding-inline .carousel__scroller {
    --padding-block: 5rem;

    padding-block: var(--padding-block);
    margin-block-start: calc(var(--padding-block) * -1);
  }

  // Normalize gap size accross "cards carousels"
  &.carousel__cards .carousel__scroller {
    @include mq($from: l) {
      --slide-gap: 3rem 2rem;
    }

    @include mq($from: xl) {
      --slide-gap: 5rem;
    }

    @include mq($from: xxl) {
      --slide-gap: 6rem;
    }

    @include mq($from: xxxl) {
      --slide-gap: 8rem;
    }
  }

  // Hide native scrollbar on desktop (preserve scroll)
  &.touch-only-scrollbar .carousel__scroller {
    @media (hover: hover) and (pointer: fine) {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }
    }
  }

  // Hide native scrollbar (preserve scroll)
  &.no-scrollbar .carousel__scroller {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  }

  // Apply special justification on carousel content when it cannnot scroll
  &.no-scroll-center .carousel__scroller:not(.can-scroll) {
    justify-content: center;
  }
}

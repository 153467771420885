
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        































































































.smartphones-single__specs {
  &.is-light-gray {
    background-color: $c-light-gray;
  }

  @include mq(xxl) {
    ::v-deep .smartphones-specs__list,
    ::v-deep .smartphones-specs__perks,
    ::v-deep .smartphones-specs__title {
      max-width: col(8);
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.smartphones-single__similar__title {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.smartphones-single__similar__devices {
  @include mq(xs) {
    padding-top: $spacing * 5;
  }

  @include mq(m) {
    padding-top: $spacing * 10;
  }

  @include mq(l) {
    padding-top: $spacing * 13;
  }
}

.smartphones-single__similar__link {
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

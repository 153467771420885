
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
























































































































































.sticky-phone,
[class*='sticky-phone--'] {
  position: fixed;
  z-index: 200;
  bottom: 1.5rem;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 0 4rem 0 rgba($black, 0.2);
  transform: translate(-50%, 0);

  &.is-visible {
    transform: translate(-50%, 0);
  }

  @include mq(l) {
    z-index: 100;
    right: 3.5rem;
    bottom: 3.5rem;
    left: auto;
    max-width: 40rem;
    transition: transform $ease-out-quad 0.2s;
    transform: translateY(100%);

    &.is-visible {
      transform: translateY(0);
    }
  }
}

.sticky-phone__close {
  position: absolute;
  z-index: 10;
  top: 0;
  right: -2rem;
  display: none;
  padding: 1rem;
  color: $c-blue-dark;
  font-family: $ff-alt;
  font-size: 1.4rem;
  text-transform: uppercase;
  background: transparent;
  border: 0;

  .is-visible & {
    display: flex;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: $c-blue-dark;
  }

  @include mq(l) {
    top: 0;
    right: 0;
    font-weight: 900;
  }
}

.sticky-phone__inner {
  position: relative;
  z-index: 1;
  max-width: 128rem;
  margin: 0 2rem;

  @include mq(l) {
    width: 100%;
    margin: 0;
  }
}

.sticky-phone__content {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 128rem;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include mq(l) {
    padding: 1rem $spacing * 1.5 1rem $spacing;
  }
}

.sticky-phone__link {
  @include get-all-space;

  z-index: 9;
}

.sticky-phone__label,
.sticky-phone__text,
.sticky-phone__image {
  @include mq(l) {
    opacity: 0;
    transition: opacity;

    .is-visible & {
      opacity: 1;
    }
  }
}

.sticky-phone__text {
  min-width: 14rem;
}

.sticky-phone__text__title {
  @extend %fw-bold;

  display: block;
  color: $c-blue-dark;
  font-family: $ff-alt;
  font-size: 1.2rem;
  line-height: 1.15;

  @include mq(l) {
    font-size: 1.8rem;
  }
}

.sticky-phone__text__subtitle {
  @extend %fw-bold;

  display: block;
  margin-top: 0.3rem;
  color: $c-blue-dark;
  font-family: $ff-alt;
  font-size: 2rem;
  line-height: 1.2;

  @include mq(l) {
    margin-top: 1rem;
    font-size: 2.4rem;
  }
}

.sticky-phone__icon {
  flex-shrink: 0;
  fill: $c-pink-medium;
  margin-right: $spacing;

  &.icon {
    width: 4rem;
    height: 4rem;

    @include mq(l) {
      width: 5rem;
      height: 5rem;
    }
  }
}

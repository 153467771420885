.h-grid,
[class*='h-grid--'] {
  display: flex;

  &[class*='--two-half'] {
    flex-direction: column;
  }

  @include mq(m) {
    &[class*='--two-half'] {
      flex-direction: row;
    }
  }
}

.h-grid__col {
  flex-basis: 100%;

  [class*='h-grid--'][class*='--two-half'] & {
    @include mq(m) {
      flex-basis: col(6);
     }
  }
}

.voo-footer {
  position: relative;
  z-index: 2;
  background-color: $c-blue-darker;
}

.voo-footer__nav {
  position: relative;
}

.voo-footer__nav-inner {
  padding-top: $spacing * 2;
  padding-bottom: $spacing;
  border-top: 1px solid $c-anthracite-light;

  @include mq('m') {
    padding-top: $spacing * 2.5;
    padding-bottom: $spacing * 2.5;
  }

  @include mq('xl') {
    display: flex;
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
  }
}

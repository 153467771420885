.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-medium-enter-active,
.fade-medium-leave-active {
  transition: opacity 0.5s;
}

.fade-medium-enter,
.fade-medium-leave-to {
  opacity: 0;
}

.fade-absolute-enter-active,
.fade-absolute-leave-active {
  position: absolute;
  transition: opacity 0.3s;
}

.fade-absolute-enter,
.fade-absolute-leave-to {
  opacity: 0;
}

.expand-enter-active {
  overflow: hidden;
  transition: transform 0.4s ease;
  transform-origin: 0 0;
}

.expand-enter {
  transform: scaleY(0);
}

.expand-leave,
.expand-leave-active {
  overflow: hidden;
  height: 0;
}

.expand-enter-to {
  transform: scaleY(1);
}

.is-hidden {
  opacity: 0 !important;
  // transition: opacity .2s $ease;
}

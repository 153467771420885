
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



































































































































.btn--no-icon {
  margin: auto;
  margin-top: $spacing;
}

.selection {
  display: flex;
  flex-direction: column;

  @include mq(l) {
    min-height: 11rem;
  }
}

.selection__header {
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  margin-bottom: 2rem;
}

.selection__header__logo {
  height: 3rem;
  margin: 0;
  line-height: 1;

  .table & {
    margin: 0 auto;
  }
}

.selection__logo__img {
  max-height: 3rem;

  .table & {
    height: auto;
    max-height: 4rem;
  }

  .is-zuny & {
    width: auto;
    height: 2rem;
  }
}

.selection__header__link {
  @extend %fw-normal;

  color: $c-anthracite-light;
  font-size: 1.3rem;

  &::after,
  &::before {
    height: 1px;
  }

  &::after {
    background-color: $c-anthracite-light;
  }
}

.selection__products {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.selection__products__figure {
  margin: 0;
  text-align: center;

  &:not(:last-child) {
    margin: 0 0.5rem;
  }
}

.selection__products__figure__icon {
  width: 5.5rem;
  height: 3rem;
}

.selection__products__figure__title {
  @extend %fw-medium;

  display: block;
  color: $c-promo-card;
  font-size: 1rem;
  line-height: 1;
}
